#about-container {
  margin-top: 20px;
  margin-bottom: 200px;
}

.page-title {
  text-align: center;
  color: #406E8E;
}

#avatar {
  padding: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
  height: 250px;
}

#description {
  color: #406E8E;
  text-align: center;
}

#desc-text {
  color: #CBF7ED;
  text-align: center;
  padding: 10px;
  margin-left: 20%;
  margin-right: 20%;
}



#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #161925;
  text-align: center;
  padding: 5px;
  z-index: +1;
}

.icons {
  font-size: 40px;
  padding: 15px;
  color: #406e8e;
}

.icons:hover {
  color: #CBF7ED;
}

@media only screen and (max-width: 720px) {
  .icons {
    font-size: 30px;
    padding: 10px;
  }
}

#resume-container {
  padding: 15px;
}

#title {
  text-align: center;
  margin-bottom: 30px;
  color: #406E8E;
}

.skills {
  border-style: ridge;
  border-radius: 40px;
  border-color: #161925;
  border-width: 8px;
  background-color: #23395B;
  color: #CBF7ED;
  height: 485px;
  text-align: center;
  margin: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.resume-titles {
  margin-top: 5px;
  margin-bottom: 20px; 
  color: #406E8E;
}

#cv-download {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 90px;
}

#download {
  text-decoration: none;
  color: #CBF7ED;
  border-width: 5px;
  border-color: #161925;
  border-style: inset;
  background-color: #23395B;
  padding: 15px;
}

#download:hover {
  background-color: #CBF7ED;
  font-size: 18px;
  color: #23395B;
  transition: 0.3s ease-in-out;
}

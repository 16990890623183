#portfolio-container {
  margin-top: 20px;
  margin-bottom: 200px;
  padding: 15px;
}

.page-title {
  color: #406E8E;
}

.container-cards .card {
  max-width: 300px;
  height: 215px;
  margin-top: 80px;
  padding: 20px 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  transition: 0.3s ease-in-out;
  font-family: sans-serif;
  background-color: #23395B;
}



.container-cards .card:hover {
  height: 500px;
}

.container-cards .card .imgContainer {
  position: relative;
  width: 250px;
  height: 200px;
  top: -50px;
  left: 10px;
  z-index: 1;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
}

.preview-image {
  width: 250px;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  object-fit: fill;
}

.container-cards .card .content {
  position: relative;
  margin-top: -140px;
  padding: 10px 15px;
  text-align: center;
  color: #CBF7ED;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.container-cards .card:hover .content {
  visibility: visible;
  opacity: 1;
  margin-top: -40px;
  transition-delay: 0.3s;
}

.launch-link,
.github-link {
  text-decoration: none;
  color: #8EA8C3;
}

.launch-link:hover {
  color: white;
}

.github-link:hover {
  color: white;
}

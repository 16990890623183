#contact-container {
  margin-top: 20px;
  margin-bottom: 200px;
}

.page-title {
  text-align: center;
  color: #406E8E;
}

.text-box {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
}

#button {
  margin-top: 15px;
  color: #CBF7ED;
  background-color: #23395B;
}

#button:hover {
  color:#23395B;
  background-color: #CBF7ED;
}

.titles {
  color: #CBF7ED;
}

@media only screen and (max-width: 575px) {
  .text-box {
    padding-left: 20px;
    padding-right: 20px;
  }
}

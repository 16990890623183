#header {
  background-color: #161925;
  color: #406e8e;
  padding: 10px;
  display: flex;
}

#my-name{
  z-index: +3;
}

#nav {
  position: absolute;
  right: 6px;
  margin-top: 2px;
}

#hamburger {
  text-align: center;
  z-index: +2;
  width: 140px;
  background-color: #161925;
  position: absolute;
  right: 6px;
  margin-top: -5px;
  visibility: hidden;
}

@media only screen and (max-width: 600px) {
  #nav {
    visibility: hidden;
  }

  #hamburger {
    visibility: visible;
  }
}